<template>
  <div style="background-color: #d7d7d7;">
    <div class="HeaderAVI">
      <video height="100%" width="100%" playsinline autoplay muted loop>
        <source src="../../assets/video/IMG_8276.A.mp4" type="video/webm">
      </video>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderAVI',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.HeaderAVI {
  height: 65vh;
  max-width: 1240px;
  min-width: 320px;
  margin: 0 auto;
  top: 60px;
}


ol {
  list-style-type: none;
  counter-reset: num;
  margin: 0 0 0 45px;
  padding: 15px 0 5px 0;
  font-size: 16px;
}

ol li {
  position: relative;
  margin: 0 0 0 0;
  padding: 0 0 10px 0;
  line-height: 1.4;
}

ol li:before {
  content: counter(num);
  counter-increment: num;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -38px;
  width: 28px;
  height: 28px;
  background: #fff;
  color: #000;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  border-radius: 50%;
  border: 1px solid #ef6780;
}
</style>
