<script setup>

</script>

<template>
  <footer class="site-footer">
    <div class="footer-top" style="margin-top: 50px;">
      <div class="container">
        <div class="row ">
          <!-- <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="footer-widget about-widget">
              <a href="index.html">Logo</a>
             
            </div>
          </div> -->
          <div class="col-md-2 col-sm-6 col-xs-12">
            <div class="footer-widget links-widget">
              <div class="title">
                <h3>Меню</h3>
              </div><!-- /.title -->
              <ul class="links-list">
                <li> <router-link to="/">Главная</router-link> </li>
                <li> <router-link to="/about">О нас</router-link> </li>
                <li> <router-link to="/hangar">Область применения</router-link> </li>
                <li> <router-link to="/projects">Наши проекты</router-link> </li>
                <li> <router-link to="/contacts">Контакты</router-link> </li>
              </ul><!-- /.links-list -->
            </div><!-- /.footer-widget links-widget -->
          </div><!-- /.col-md-2 -->
          <div class="col-md-9 col-sm-6 col-xs-12">
            <div class="footer-widget twitter-widget">
              <div class="title">
                <h3>ООО «Стафф Ойл»</h3>
              </div><!-- /.title -->
              <div class="single-tweet">
                <p>Наша производственная компания осуществляет свою деятельность в области изготовления надувных ангаров и мягких резервуаров уже на протяжении двенадцати лет, что дало нам возможность накопить колоссальный опыт в этой области. Производственные мощности состоят из передового и современного оборудования; для изготовления нашей продукции используется только проверенный высококлассный материал; специалисты на нашем производстве обладают как надлежащим образованием, так и всеми необходимыми навыками – именно благодаря этому выпускается надежная, износостойкая и долговечная продукция, полностью соответствующая мировым стандартам и требованиям.</p>
                <div class="name"><a type="button" href="https://staffoil.ru/index.html" class="btn-2"
                    target="_blank">Перейти на сайт
                    мягких резервуаров</a></div>
                <!-- /.name -->
              </div><!-- /.single-tweet -->
            </div><!-- /.footer-widget twitter-widget -->
          </div><!-- /.col-md-4 -->
        </div><!-- /.row -->
      </div><!-- /.container -->
    </div><!-- /.footer-top -->
  </footer>
  <div class="footer-bottom">
    <div class="container">
      <div class="copy-text pull-left">
        <p>2024 © StaffAngar - Все права защищены</p>
      </div><!-- /.copy-text -->
      <div class="right-link pull-right">
        <a href="#">Политика конфиденциальности</a>
      </div><!-- /.right-link -->
    </div><!-- /.container -->
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
