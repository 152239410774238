import { createRouter, createWebHashHistory } from 'vue-router'
import MainLayout from '@/templates/MainLayout.vue'
import SubLayout from '@/templates/SubLayout.vue'
const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '', name: 'Home',
        component: () => import('../views/HomeView.vue')
      }
    ]
  },
  {
    path: '/',
    component: SubLayout,
    children: [
      {
        path: 'about', name: 'about',
        component: () => import('../views/AboutView.vue'),
      }
    ]
  },
  {
    path: '/contacts',
    name:'contacts',
    component: () => import('../views/ContactsView.vue'),
  },
  {
    path: '/cathalog',
    component: SubLayout,
    props: { title: 'Область применения' },
    children: [
      {
        path: '', name: 'cathalog',
        component: () => import('../views/CathalogView.vue'),
      }
    ]
  },
  {
    path: '/projects',
    component: SubLayout,
    props: { title: 'Наши проекты' },
    children: [
      {
        path: '', name: 'projects',
        component: () => import('../views/ProjectsView.vue'),
      }
    ]
  },
  {
    path: '/calculation',
    component: () => import('../views/СalculationView.vue')
  },
  {
    path: '/hangar',
    component: SubLayout,
    props: { title: 'Область применения' },
    children: [
      {
        path: '', name: 'hangar',
        component: () => import('../views/HangarView.vue'),
      }
    ]
  },
  {
    path: '/hangar/entertainment',
    component: SubLayout,
    props: { title: 'Область применения' },
    children: [
      {
        path: '', name: 'entertainment',
        component: () => import('../views/hangars/EntertainmentView.vue'),
      }
    ]
  },
  {
    path: '/hangar/keeping',
    component: SubLayout,
    children: [
      {
        path: '', name: 'keeping',
        component: () => import('../views/hangars/KeepingView.vue'),
      }
    ]
  },
  {
    path: '/hangar/production',
    component: SubLayout,
    children: [
      {
        path: '', name: 'production',
        component: () => import('../views/hangars/ProductionView.vue'),
      }
    ]
  },
  {
    path: '/hangar/trading',
    component: SubLayout,
    children: [
      {
        path: '', name: 'trading',
        component: () => import('../views/hangars/TradingView.vue'),
      }
    ]
  },
  {
    path: '/hangar/warehouse',
    component: SubLayout,
    children: [
      {
        path: '', name: 'warehouse',
        component: () => import('../views/hangars/WarehouseView.vue'),
      }
    ]
  },
  {
    path: '/thanks',
    component: SubLayout,
    props: { title: 'Стафф Ойл' },
    children: [
      {
        path: '', name: 'thanks',
        component: () => import('../views/ThanksView.vue'),
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
